<script setup lang="ts">
import { SuiteErrorMessage } from '@shipcloud/suite-components'
const shipcloudUrl = import.meta.env.VITE_SEND_CORE_APP_HOST
</script>

<template>
  <div class="mx-auto max-w-(--breakpoint-md)">
    <div class="justify-content mx-4 my-10 flex items-center md:my-20 lg:my-32">
      <SuiteErrorMessage>
        <i18n-t keypath="App.Error.accountError402" tag="p">
          <template #shipcloud_url>
            <a :href="shipcloudUrl" target="_blank">
              {{ shipcloudUrl }}
            </a>
          </template>
        </i18n-t>
      </SuiteErrorMessage>
    </div>
  </div>
</template>
