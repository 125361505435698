<script lang="ts" setup>
import { DeliveryFilter } from '@/api/parcelCore'
import { useDeliveriesFilter } from '@/components/parcelCore'
import { useDeliveriesFilterStore, useDeliveriesStore } from '@/stores/parcelCore'
import {
  SuiteErrorMessage,
  SuiteFilter,
  SuiteIcon,
  SuiteLoader,
  SuitePageHeader,
  SuitePagination,
  SuiteTableNested,
  type SuitePaginationEventUpdate
} from '@shipcloud/suite-components'
import { computed, ref, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { deliveriesToSuiteTableNested } from './helpers'

const i18n = useI18n()
const { t } = i18n
const route = useRoute()
const router = useRouter()
const deliveriesStore = useDeliveriesStore()
const filterStore = useDeliveriesFilterStore()
const deliveriesFilterProps = useDeliveriesFilter()
const pagination = ref({ pageNumber: 1, pageSize: 25 })
const paginationUpdate = (payload: SuitePaginationEventUpdate) =>
  (pagination.value = { pageNumber: payload.page, pageSize: payload.pageSize })
const fetchAll = () => deliveriesStore.fetchAll(filterStore.filter, pagination.value)
watchEffect(() => {
  const parsed = DeliveryFilter.safeParse(route.query)
  if (parsed.success && Object.keys(parsed.data).length) filterStore.filter = parsed.data
})
watchEffect(() => router.push({ query: { ...filterStore.filter } }))
watch([() => filterStore.filter, pagination], fetchAll, { immediate: true, deep: true })

const tableProps = computed(() =>
  deliveriesToSuiteTableNested(deliveriesStore.deliveries, i18n, router)
)
</script>
<template>
  <SuitePageHeader
    :header="t('Office.ShipmentsDashboard.pageHeader')"
    :subheader="t('Office.ShipmentsDashboard.pageSubheader')"
  >
    <SuiteFilter class="mt-2" v-model="filterStore.filter" v-bind="deliveriesFilterProps" />
  </SuitePageHeader>
  <SuiteErrorMessage class="rounded-none" v-if="deliveriesStore.error">
    {{ t('App.Error.fetchShipmentsError') }}
  </SuiteErrorMessage>
  <SuiteLoader page :loading="deliveriesStore.loading && deliveriesStore.deliveries.length === 0" />
  <SuiteLoader :loading="deliveriesStore.loading && deliveriesStore.deliveries.length > 0">
    <div class="mx-auto max-w-(--breakpoint-2xl) space-y-5 py-5">
      <SuiteTableNested v-if="tableProps" v-bind="tableProps" class="w-full bg-white">
        <template #body_0="{ cell, row }">
          <span class="inline-flex items-center gap-2" v-if="row.isParent">
            {{ cell }}
            <button @click="row.toggleOpenParent()">
              <SuiteIcon :icon="row.isOpenParent ? 'ChevronUp' : 'ChevronDown'" class="size-4" />
            </button>
          </span>
        </template>
      </SuiteTableNested>
      <SuitePagination
        id="pagination"
        :page="pagination.pageNumber"
        :pageSize="pagination.pageSize"
        :pageSizeOptions="[5, 10, 25, 50]"
        :itemCount="deliveriesStore.total"
        @update="paginationUpdate"
      >
        <template #status="{ itemCount, itemPageEnd, itemPageOffset }">
          {{ t('App.pagination', { itemPageOffset, itemPageEnd, itemCount }) }}
        </template>
      </SuitePagination>
    </div>
  </SuiteLoader>
  <div
    v-if="!deliveriesStore.loading && deliveriesStore.deliveries.length == 0"
    class="bg-white p-10 text-center"
  >
    {{ t('Office.ShipmentsDashboard.noShipmentsFound') }}
  </div>
</template>
