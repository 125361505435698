<script setup lang="ts">
import { useShippingRuleTreesStore } from '@/stores/shippingRuleTrees'
import { DialogBox, SuiteButton, SuiteIcon, ThemeEnum } from '@shipcloud/suite-components'
import { Handle, type NodeProps, Position } from '@vue-flow/core'
import { NodeToolbar } from '@vue-flow/node-toolbar'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

defineProps<{
  nodeProps: NodeProps
}>()
const validateDialogOpen = defineModel<boolean>({ default: false })
const emit = defineEmits<{
  (e: 'save'): void
}>()
const store = useShippingRuleTreesStore()
const { resolutionData, latestShippingRuleTree } = storeToRefs(store)
const openSaveDialog = computed(() => !latestShippingRuleTree.value.persisted)
const resolutionDataInput = ref(JSON.stringify(resolutionData.value, null, 2))
const resolutionDataValid = ref(true)
</script>

<template>
  <DialogBox
    v-model="validateDialogOpen"
    dialog-size="large"
    :dialog-title="t('Office.ShippingRules.validateDialogHeader')"
    icon-name="CircleCheck"
  >
    <div class="mt-4 max-w-96 min-w-96 px-4">
      <template v-if="openSaveDialog">
        <p>{{ t('Office.ShippingRules.unpersistedDialogText') }}</p>
      </template>
      <template v-else>
        <p v-if="resolutionDataValid" class="mb-2 text-sm text-gray-600">
          {{ t('Office.ShippingRules.validateDialogText') }}
        </p>
        <p v-else class="text-form-error mb-2 text-sm">
          {{ t('Office.ShippingRules.validateError') }}
        </p>
        <textarea
          v-model="resolutionDataInput"
          spellcheck="false"
          class="h-96 w-full font-mono text-xs"
          :class="{ 'border-form-error border': !resolutionDataValid }"
        />
      </template>
    </div>
    <div class="p-4 text-right">
      <SuiteButton
        @on-click="validateDialogOpen = false"
        :theme="ThemeEnum.BLUE_OUTLINE"
        class="mr-2"
      >
        {{ t('Office.ShippingRules.close') }}
      </SuiteButton>
      <SuiteButton v-if="openSaveDialog" @on-click="emit('save')" ata-testid="root-save-btn">
        {{ t('Office.ShippingRules.submit') }}
      </SuiteButton>
      <SuiteButton
        v-else
        @on-click="resolutionDataValid = store.validate(resolutionDataInput)"
        data-testid="root-validate-btn"
      >
        {{ t('Office.ShippingRules.validate') }}
      </SuiteButton>
    </div>
  </DialogBox>
  <NodeToolbar
    v-if="latestShippingRuleTree"
    class="flex"
    :is-visible="nodeProps.data.toolbarVisible"
    :position="Position.Top"
  >
    <SuiteButton
      @on-click="validateDialogOpen = true"
      class="-mb-6 h-7 w-7 rounded-full p-1.5"
      :theme="ThemeEnum.BLUE_OUTLINE"
      icon="CircleCheck"
    />
  </NodeToolbar>

  <div class="border-sky flex flex-col rounded-md border py-3 text-sm">
    <div class="flex justify-center">
      <SuiteIcon icon="RrRoot" class="size-8" />
    </div>
    <div class="mt-2 px-2 text-center text-xs text-gray-600">
      {{ t('Office.ShippingRules.rootNodeText') }}
    </div>
  </div>

  <Handle type="source" :position="Position.Right" />
</template>
