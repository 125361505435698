import { z } from 'zod'

export const ShipmentFilter = z.object({
  carrier: z.string().optional(),
  carrier_tracking_no: z.string().optional(),
  created_at_gt: z.string().optional(),
  created_at_lt: z.string().optional(),
  reference_number: z.string().optional(),
  service: z.string().optional(),
  shipcloud_tracking_no: z.string().optional(),
  shipment_type: z.string().optional(),
  source: z.string().optional(),
  to_be_picked_up: z.string().optional(),
  tracking_status: z.string().optional(),
  tracking_status_not: z.string().optional()
})

export type ShipmentFilter = z.infer<typeof ShipmentFilter>
