import { z } from 'zod'
import { Address } from '../address'

export const PickupTime = z.object({
  earliest: z.string(),
  latest: z.string()
})
export const PickupRequest = z.object({
  id: z.string(),
  carrier: z.string(),
  carrier_pickup_number: z.string(),
  shipments: z.object({ id: z.string() }).array(),
  pickup_time: PickupTime.optional(),
  pickup_address: Address
})
export type PickupRequest = z.infer<typeof PickupRequest>

export const PickupRequestFilter = z.object({})
export type PickupRequestFilter = z.infer<typeof PickupRequestFilter>

export const GetPickupRequestsResponse = z.object({
  total: z.number(),
  pickupRequests: PickupRequest.array()
})
export type GetPickupRequestsResponseT = z.infer<typeof GetPickupRequestsResponse>

export const NewPickupRequest = z.object({
  carrier: z.string(),
  pickup_time: PickupTime,
  pickup_address: Address.optional(),
  shipments: z.object({ id: z.string() }).array()
})
export type NewPickupRequest = z.infer<typeof NewPickupRequest>
