<script setup lang="ts">
import { usePickupRequestDetails } from '@/composables/usePickupRequestDetails'
import router from '@/router'
import { usePickupRequestsStore } from '@/stores/pickupRequests'
import { useShipmentsStore } from '@/stores/shipments'
import {
  SuiteErrorMessage,
  SuiteIcon,
  SuiteItemDetails,
  SuiteLoader
} from '@shipcloud/suite-components'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export type PickupRequestDetailsProps = { id: string }
const props = defineProps<PickupRequestDetailsProps>()
const pickupRequestsStore = usePickupRequestsStore()
const { t } = useI18n()
const shipmentsStore = useShipmentsStore()
const { buildPickupRequestDetails, suiteItemDetailsProps } = usePickupRequestDetails(
  shipmentsStore,
  useI18n,
  router
)
const pickupRequest = computed(() => pickupRequestsStore.pickupRequestById(props.id))
if (!pickupRequest.value) pickupRequestsStore.fetchPickupRequest(props.id)

watch(
  pickupRequest,
  () => {
    if (pickupRequest.value) buildPickupRequestDetails(pickupRequest.value)
  },
  { immediate: true }
)
</script>

<template>
  <SuiteLoader page :loading="pickupRequestsStore.loading" />
  <template v-if="!pickupRequestsStore.loading">
    <SuiteErrorMessage v-if="pickupRequestsStore.error">
      {{ t('App.Error.fetchPickupRequestError') }}
    </SuiteErrorMessage>
    <SuiteErrorMessage v-else-if="shipmentsStore.error">
      {{ t('App.Error.fetchShipmentsError') }}
    </SuiteErrorMessage>
    <SuiteItemDetails v-if="suiteItemDetailsProps" v-bind="suiteItemDetailsProps">
      <template #header>
        <RouterLink
          :to="{ name: 'pickup-requests-dashboard' }"
          class="inline-flex items-center"
          data-identifier="link-back"
        >
          <SuiteIcon icon="ChevronLeft" class="mr-2 size-4" />
          {{ t('Office.ShipmentDetails.back') }}
        </RouterLink>
      </template>
    </SuiteItemDetails>
  </template>
</template>
