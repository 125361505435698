<script setup lang="ts">
import LinkCard from '@/components/LinkCard.vue'
import { DualImages, EntryPage } from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>

<template>
  <div class="mx-auto h-full max-w-(--breakpoint-2xl)">
    <EntryPage>
      <template #left>
        <DualImages
          leftImageSrc="https://suite-static-assets.s3.eu-central-1.amazonaws.com/photos/suite_entry_photo1.png"
          rightImageSrc="https://suite-static-assets.s3.eu-central-1.amazonaws.com/photos/suite_entry_photo2.png"
        />
      </template>

      <template #right>
        <div id="linkcards" class="space-y-4">
          <LinkCard
            :title="t('SuiteEntry.shippingTitle')"
            :subtitle="t('SuiteEntry.shipmentsDashboard.subtitle')"
            icon="ShTrack"
            name="parcel-core-shipments-dashboard"
          />
        </div>
      </template>
    </EntryPage>
  </div>
</template>
