<script setup lang="ts">
import { useAuthenticationV2 } from '@/composables/useAuthenticationV2'
import { languages, setLocale } from '@/i18n'
import { useAuthenticationV2Store } from '@/stores/authenticationV2'
import {
  HeaderDropdown,
  SuiteAppLayout,
  SuiteHeaderNavigation,
  SuiteIcon,
  type SuiteHeaderDropdownMenuItemSelected
} from '@shipcloud/suite-components'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink, useRouter } from 'vue-router'

const { t, locale, tm } = useI18n({ useScope: 'global' })
const router = useRouter()
const store = useAuthenticationV2Store()
const { removeAuthentication, editAuthentication } = useAuthenticationV2()
const headerBarProps = { logoHref: router.resolve({ name: 'suite-entry-v2' }).href }
const footerBarProps = {
  languages,
  initialLanguage: locale.value,
  linkDisplayNames: tm('FooterBar.links')
}
const userEmail = computed(() => store.payload?.sub)
const headerDropdownItems = [
  { id: 'edit_password', label: t('App.HeaderDropdown.edit_password') },
  { id: 'logout', label: t('App.HeaderDropdown.logout') }
]
const headerItemSelected = (event: SuiteHeaderDropdownMenuItemSelected) => {
  switch (event.item.id) {
    case 'logout':
      return removeAuthentication()
    case 'edit_password':
      return editAuthentication()
  }
}
const navigationItems = [
  {
    id: 'suite-entry',
    title: t('Navigation.shipping'),
    children: [
      {
        id: 'parcel-core-shipments-dashboard',
        title: t('Navigation.shipments')
      }
    ]
  }
]
</script>

<template>
  <SuiteAppLayout :headerBarProps :footerBarProps @language-changed="setLocale($event.newLanguage)">
    <template #header-center>
      <SuiteHeaderNavigation :items="navigationItems">
        <template #nav-items="{ navItem, closeNav }">
          <RouterLink
            v-if="router.hasRoute(navItem.id)"
            :to="{ name: navItem.id }"
            @click="closeNav"
            active-class="text-sky"
            class="hover:text-sky"
          >
            {{ navItem.title }}
          </RouterLink>
          <a v-else :href="navItem.id" class="hover:text-sky">
            {{ navItem.title }}
          </a>
        </template>
      </SuiteHeaderNavigation>
    </template>
    <template #header-right v-if="userEmail">
      <HeaderDropdown
        :items="headerDropdownItems"
        :title="userEmail"
        @on-select="headerItemSelected"
        :darkMode="false"
      >
        <template #menu-icon>
          <SuiteIcon icon="User" class="mr-2 size-4" />
        </template>
      </HeaderDropdown>
    </template>
    <template #default><RouterView /></template>
  </SuiteAppLayout>
</template>
