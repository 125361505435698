<script lang="ts" setup>
import type { ShipcloudApiError } from '@/api/shipcloud'
import { errorOn } from '@/api/shipcloud'
import { NewShipment } from '@/api/shipcloud/shipment'
import { ShipmentFormAddressForm, ShipmentFormCustomsDeclaration } from '@/components/ShipmentForm'
import {
  SuiteAccordion,
  SuiteButton,
  SuiteCollapser,
  SuiteContentPanel,
  SuiteErrorMessage,
  SuiteIcon,
  SuiteInput,
  SuiteInputLabel,
  SuiteLoader,
  SuitePanelHeader,
  ThemeEnum
} from '@shipcloud/suite-components'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

type AddressFormProps = {
  apiModelName: string
  attribute: 'from' | 'to'
  title: string
  opened: boolean
}
const props = defineProps<{
  leftAddress: AddressFormProps
  rightAddress: AddressFormProps
  loading: boolean
  saving: boolean
  shipmentQuoteLoading: boolean
  showCustomsDeclaration: boolean
  error: ShipcloudApiError | undefined
}>()
const shipment = defineModel<NewShipment>()
const { t } = useI18n()
const collapserDetailsOpen = ref(true)
const collapserDescriptionOpen = ref(false)
const collapserPackagesOpen = ref(false)
const editableDescriptionProperties = [
  'description',
  'reference_number',
  'notification_email'
] as const
const packageDimensions = ['length', 'width', 'height'] as const
const errorPanelPresent = computed(
  () => props.error && !(props.loading || props.saving || props.shipmentQuoteLoading)
)
</script>

<template>
  <SuiteLoader page :loading />
  <SuiteErrorMessage :scroll-into-view="true" v-if="errorPanelPresent" class="rounded-none">
    <div class="mx-auto max-w-(--breakpoint-2xl) px-8">
      {{ t('Office.ShipmentForm.error') }}
      <div v-for="err in error?.errors?.slice(0, 3)" :key="err">
        {{ err }}
      </div>
    </div>
  </SuiteErrorMessage>
  <SuiteContentPanel v-if="!loading">
    <SuitePanelHeader>
      <RouterLink
        :to="{ name: 'shipments-dashboard' }"
        id="back-button"
        class="inline-flex items-center"
      >
        <SuiteIcon icon="ChevronLeft" class="mr-2 size-4" />
        {{ t('Office.ShipmentForm.back') }}
      </RouterLink>
    </SuitePanelHeader>
    <div v-if="shipment" class="bg-white">
      <SuiteAccordion>
        <SuiteCollapser v-model:open="collapserDetailsOpen" :no-padding="true">
          <template #header>
            <span class="text-xl font-bold">{{ t('Office.ShipmentForm.shipmentDetails') }}</span>
          </template>
          <div class="px-4 pb-4">
            <div
              class="grid grid-cols-1 gap-10 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2"
            >
              <ShipmentFormAddressForm
                :api-model-name="leftAddress.apiModelName"
                v-model="shipment[leftAddress.attribute]"
                :title="leftAddress.title"
                :opened="leftAddress.opened"
                :error
              />
              <ShipmentFormAddressForm
                :api-model-name="rightAddress.apiModelName"
                v-model="shipment[rightAddress.attribute]"
                :title="rightAddress.title"
                :opened="rightAddress.opened"
                :error
                edit-button
              />
            </div>
            <div class="mt-5 flex justify-end gap-3">
              <SuiteButton
                id="details-to-description"
                class="px-5"
                @click="() => (collapserDescriptionOpen = true)"
              >
                {{ t('Office.ShipmentForm.next') }}
              </SuiteButton>
            </div>
          </div>
        </SuiteCollapser>

        <SuiteCollapser v-model:open="collapserDescriptionOpen" :no-padding="true">
          <template #header>
            <span class="text-xl font-bold">{{
              t('Office.ShipmentForm.shipmentDescription')
            }}</span>
          </template>
          <div class="px-4 pb-4">
            <div class="mb-3 inline-flex items-center justify-start gap-2">
              <div class="bg-sky bg-opacity-50 h-4 w-1"></div>
              <div class="text-sm leading-snug font-bold text-gray-600">
                {{ t('Office.ShipmentForm.descriptionColumn') }}
              </div>
            </div>

            <div class="grid grid-flow-row gap-2">
              <template v-for="property in editableDescriptionProperties" :key="property">
                <SuiteInputLabel class="">
                  <template #label>
                    <div :class="['w-40', { 'text-red-300': errorOn(error, { property }) }]">
                      {{ t(`Shipment.${property}`) }}
                    </div>
                  </template>
                  <SuiteInput
                    :placeholder="t(`Shipment.${property}`)"
                    v-model="shipment[property]"
                    :class="['w-72', { 'border-red-300': errorOn(error, { property }) }]"
                  />
                </SuiteInputLabel>
              </template>
            </div>

            <ShipmentFormCustomsDeclaration
              v-if="showCustomsDeclaration"
              v-model="shipment.customs_declaration"
              :error="error"
            />
            <div v-if="shipment" class="mt-5 flex justify-end gap-3">
              <SuiteButton
                class="px-5"
                id="description-to-details"
                :theme="ThemeEnum.BLUE_OUTLINE"
                @click="() => (collapserDetailsOpen = true)"
              >
                {{ t('Office.ShipmentForm.back') }}
              </SuiteButton>
              <SuiteButton
                class="px-5"
                id="description-to-packages"
                @click="() => (collapserPackagesOpen = true)"
              >
                {{ t('Office.ShipmentForm.next') }}
              </SuiteButton>
            </div>
          </div>
        </SuiteCollapser>

        <SuiteCollapser v-model:open="collapserPackagesOpen" :no-padding="true">
          <template #header>
            <span class="text-xl font-bold">{{ t('Office.ShipmentForm.packages') }}</span>
          </template>
          <div class="px-4 pb-4">
            <div class="text-sm leading-relaxed whitespace-break-spaces text-gray-600">
              {{ t('Office.ShipmentForm.packagesHint') }}
            </div>
            <div class="mt-5 flex justify-start">
              <div>
                <div class="text-m leading-tight font-bold text-gray-600">
                  {{ t('Package.dimensions') }}
                </div>
                <div class="py-2 text-sm text-gray-600">
                  {{ t('Office.ShipmentForm.packageDimensionsHint') }}
                </div>

                <div class="flex gap-5">
                  <SuiteInputLabel
                    v-for="property in packageDimensions"
                    :key="property"
                    labelPosition="top"
                  >
                    <template #label>{{ t(`Package.${property}`) }}</template>
                    <span class="flex items-center gap-3">
                      <SuiteInput
                        v-model="shipment.package[property]"
                        :class="['w-24', { 'border-red-300': errorOn(error, { property }) }]"
                      />
                      <SuiteIcon v-if="property !== 'height'" icon="X" class="size-4" />
                      <span v-else class="text-sm text-gray-600">
                        {{ t('Office.ShipmentForm.packageDimensionUnit') }}
                      </span>
                    </span>
                  </SuiteInputLabel>
                </div>
              </div>
              <div class="lg:w-16" />
              <div class="px-5">
                <div class="text-m leading-tight font-bold text-gray-600">
                  {{ t('Package.weight') }}
                </div>
                <div class="pt-2 text-sm text-gray-600">
                  {{ t('Office.ShipmentForm.packageWeightHint') }}
                </div>
                <SuiteInputLabel labelPosition="top">
                  <template #label>{{ t(`Package.weight`) }}</template>
                  <span class="flex items-center gap-3">
                    <SuiteInput
                      v-model="shipment.package.weight"
                      :class="[
                        'w-24',
                        { 'border-red-300': errorOn(error, { property: 'weight' }) }
                      ]"
                    />
                    <span class="text-sm text-gray-600">
                      {{ t('Office.ShipmentForm.packageWeightUnit') }}
                    </span>
                  </span>
                </SuiteInputLabel>
              </div>
            </div>
            <div class="mt-5 flex justify-end gap-3">
              <SuiteButton
                class="px-5"
                id="packages-to-description"
                :theme="ThemeEnum.BLUE_OUTLINE"
                @click="() => (collapserDescriptionOpen = true)"
              >
                {{ t('Office.ShipmentForm.back') }}
              </SuiteButton>
            </div>
          </div>
        </SuiteCollapser>
      </SuiteAccordion>
    </div>

    <template #aside><slot /></template>
  </SuiteContentPanel>
</template>
