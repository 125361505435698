<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

import LinkCard from '@/components/LinkCard.vue'
import { localizeURL } from '@/helpers'
import { useCredentialsStore } from '@/stores/credentials'
import { DualImages, EntryPage } from '@shipcloud/suite-components'

const { t, locale } = useI18n()
const returnAuthUrl = import.meta.env.VITE_RETURN_AUTH_URL
const store = useCredentialsStore()
const { returnbirdAccountId, routingRulesEnabled } = storeToRefs(store)
</script>

<template>
  <div class="mx-auto h-full max-w-(--breakpoint-2xl)">
    <EntryPage>
      <template #left>
        <DualImages
          leftImageSrc="https://suite-static-assets.s3.eu-central-1.amazonaws.com/photos/suite_entry_photo1.png"
          rightImageSrc="https://suite-static-assets.s3.eu-central-1.amazonaws.com/photos/suite_entry_photo2.png"
        />
      </template>

      <template #right>
        <div id="linkcards" class="space-y-4">
          <LinkCard
            :title="t('SuiteEntry.shippingTitle')"
            :subtitle="t('SuiteEntry.sendDashboard.subtitle')"
            icon="Tracking"
            :href="localizeURL(locale)"
          />

          <LinkCard
            :title="t('SuiteEntry.shippingTitle')"
            :subtitle="t('SuiteEntry.shipmentsDashboard.subtitle')"
            icon="ShTrack"
            name="shipments-dashboard"
          />

          <LinkCard
            v-if="routingRulesEnabled"
            :title="t('SuiteEntry.shippingRules.title')"
            :subtitle="t('SuiteEntry.shippingRules.subtitle')"
            icon="RoutingRules"
            name="routing-rules"
          />

          <LinkCard
            :title="t('SuiteEntry.returns.title')"
            :subtitle="t('SuiteEntry.returns.subtitle')"
            icon="ParcelCycle"
            :href="returnAuthUrl"
            v-if="returnbirdAccountId"
          />
        </div>
      </template>
    </EntryPage>
  </div>
</template>
