<script setup lang="ts">
import type {
  DataColumn,
  DataTableRowSelectEvent,
  PaginationFilters,
  SuiteFilterProps
} from '@shipcloud/suite-components'
import {
  DataTable,
  StatusCaption,
  SuiteErrorMessage,
  SuiteFilter,
  SuitePageHeader
} from '@shipcloud/suite-components'

import { storeToRefs } from 'pinia'
import { computed, ref, watchEffect, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { ShipmentFilter, TrackingStatus, type Shipment } from '@/api/shipcloud/shipment'
import DataTableCarrier from '@/components/DataTableCarrier.vue'
import DataTableCountry from '@/components/DataTableCountry.vue'
import { addressLines, lastTrackingEvent, trackingStatusColors } from '@/helpers'
import { useCarriersStore } from '@/stores/carriers'
import { useShipmentFilterStore } from '@/stores/shipmentFilter'
import { useShipmentsStore } from '@/stores/shipments'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const carriersStore = useCarriersStore()
const { carriers, services } = storeToRefs(carriersStore)
carriersStore.fetchCarriers()
const shipmentsStore = useShipmentsStore()
const { loading, shipments, shipmentsTotal, error } = storeToRefs(shipmentsStore)
const shipmentFilterStore = useShipmentFilterStore()
if (Object.keys(ShipmentFilter.parse(route.query)).length != 0) {
  shipmentFilterStore.filter = ShipmentFilter.parse(route.query)
}
const rowsPerPageOptions = [25, 50, 100]
const paginationOptions = { page: 1, per_page: rowsPerPageOptions[0] }
const expandedRowIds: Ref<string[]> = ref([])

const carrierFilterOptions = computed(() => {
  const options: { label: string; value: any }[] = []

  Object.values(carriers.value).forEach((carrier) => {
    options.push({ label: carrier.display_name, value: carrier.name })
  })

  options.sort((a, b) => a.label.localeCompare(b.label))
  options.unshift({ label: t('Office.ShipmentsDashboard.showAll'), value: '' })

  return options
})
const serviceFilterOptions = computed(() => {
  const options: { label: string; value: any }[] = []

  services.value.forEach((serviceName) => {
    options.push({ label: t(`Shipment.services.${serviceName}`), value: serviceName })
  })

  options.sort((a, b) => a.label.localeCompare(b.label))
  options.unshift({ label: t('Office.ShipmentsDashboard.showAll'), value: '' })

  return options
})
const trackingStatusFilterOptions = computed(() => {
  const options: { label: string; value: any }[] = []

  const trackingStates = Object.keys(TrackingStatus.Values)
  trackingStates.forEach((state) => {
    options.push({ label: t(`TrackingEvent.status.${state}`), value: state })
  })

  options.sort((a, b) => a.label.localeCompare(b.label))
  options.unshift({ label: t('Office.ShipmentsDashboard.showAll'), value: '' })
  return options
})

const columns: DataColumn[] = [
  {
    field: 'carrier_tracking_no',
    label: t('Package.carrier_tracking_no'),
    type: 'text',
    sortable: false,
    filterable: false,
    filterMatchMode: 'contains',
    visibleByDefault: true
  },
  {
    field: 'tracking_status',
    label: t('Package.status'),
    type: 'select',
    sortable: false,
    filterable: false,
    filterMatchMode: 'contains',
    visibleByDefault: true
  },
  {
    field: 'carrier',
    label: t('Shipment.carrier'),
    type: 'select',
    sortable: false,
    filterable: false,
    filterMatchMode: 'contains',
    visibleByDefault: true
  },
  {
    field: 'service',
    label: t('Shipment.service'),
    type: 'select',
    sortable: false,
    filterable: false,
    filterMatchMode: 'contains',
    visibleByDefault: true
  },
  {
    field: 'created_at_gt',
    label: t('Shipment.created_at'),
    type: 'calendar',
    sortable: false,
    filterable: false,
    filterMatchMode: 'contains',
    visibleByDefault: true
  },
  {
    field: 'recipient',
    label: t('Office.ShipmentsDashboard.recipient'),
    type: 'text',
    sortable: false,
    filterable: false,
    filterMatchMode: 'contains',
    visibleByDefault: true
  },
  {
    field: 'country',
    label: t('Office.ShipmentsDashboard.shipToCountry'),
    type: 'text',
    sortable: false,
    filterable: false,
    filterMatchMode: 'contains',
    visibleByDefault: true
  }
]

const shipmentsArray = computed(() => {
  return Object.values(shipments.value)
})

function onRowSelect(event: DataTableRowSelectEvent) {
  router.push({
    name: 'shipment-details',
    params: { id: event?.data?.id }
  })
}

function getTrackingStatus(shipment: Shipment, index: number = 0) {
  return lastTrackingEvent(shipment.packages[index])?.status || 'ignored'
}

function formatDate(date: string) {
  let d = new Date(date)
  return (
    ('0' + d.getUTCDate()).slice(-2) +
    '-' +
    ('0' + (d.getUTCMonth() + 1)).slice(-2) +
    '-' +
    d.getUTCFullYear()
  )
}

function getRecipient(shipment: Shipment) {
  const companyOrLastName = shipment.to?.company ? 'company' : 'last_name'

  return shipment.to && addressLines(shipment.to, [companyOrLastName, 'zip_code', 'city']).join(' ')
}

function updateFilters(paginationFilters: PaginationFilters) {
  paginationOptions.page = paginationFilters.page + 1
  paginationOptions.per_page = paginationFilters.rowsPerPage
}

function getItems() {
  shipmentsStore.fetchShipments(shipmentFilterStore.filter, paginationOptions)
}

const isShipmentMultiColli = (shipment: Shipment) => {
  return shipment?.packages?.length > 1
}

const toggleMultiColliDisplay = (shipment: Shipment) => {
  if (!shipment?.id) {
    return false
  }

  if (isRowExpanded(shipment)) {
    const index = expandedRowIds.value.indexOf(shipment.id)

    if (index > -1) {
      expandedRowIds.value.splice(index, 1)
    }
  } else {
    expandedRowIds.value.push(shipment.id)
  }
}

const isRowExpanded = (shipment: Shipment) => {
  return expandedRowIds.value.includes(shipment?.id)
}

const temporaryPackageName = (shipment: Shipment, index: number) => {
  // TODO: This should be replaced when the actual package tracking number is added to the shipments API response
  return `${shipment?.carrier_tracking_no} (${index + 1})`
}

const shipmentPackages = (shipment: Shipment) => {
  if (shipment?.packages?.length) {
    return shipment.packages
  }

  return []
}

const shipmentStatus = (shipment: Shipment): TrackingStatus => {
  const firstStatus: TrackingStatus = getTrackingStatus(shipment, 0)
  let sameStatus = true
  let problemStatus = false

  for (let i = 0; i < shipment?.packages?.length; i++) {
    const status = getTrackingStatus(shipment, i)

    if (status !== firstStatus) {
      sameStatus = false
    }

    if (['destroyed', 'exception', 'not_delivered', 'exception'].includes(status)) {
      problemStatus = true
    }
  }

  if (sameStatus) {
    return firstStatus
  }

  if (problemStatus) {
    return 'exception'
  }

  return 'transit'
}

const shipmentFilters = (): SuiteFilterProps['filters'] => ({
  carrier_tracking_no: {
    label: t('Package.carrier_tracking_no'),
    placeholder: t('Package.carrier_tracking_no'),
    type: 'text'
  },
  reference_number: {
    label: t('Shipment.reference_number'),
    placeholder: t('Shipment.reference_number'),
    type: 'text'
  },
  tracking_status: {
    label: t('Package.status'),
    type: 'select',
    options: trackingStatusFilterOptions.value
  },
  carrier: {
    label: t('Shipment.carrier'),
    type: 'select',
    options: carrierFilterOptions.value
  },
  service: {
    label: t('Shipment.service'),
    type: 'select',
    options: serviceFilterOptions.value
  },
  created_at_gt: {
    label: t('Shipment.created_at'),
    type: 'date'
  }
})

watchEffect(() => {
  router.push({ query: { ...shipmentFilterStore.filter } })
})

defineExpose({
  updateFilters,
  paginationOptions,
  onRowSelect,
  getItems,
  toggleMultiColliDisplay,
  expandedRowIds,
  shipmentPackages,
  shipmentStatus,
  shipmentFilterStore
})

getItems()
</script>

<template>
  <SuitePageHeader
    :header="t('Office.ShipmentsDashboard.pageHeader')"
    :subheader="t('Office.ShipmentsDashboard.pageSubheader')"
  >
    <SuiteFilter
      class="mt-2"
      :filters="shipmentFilters()"
      v-model="shipmentFilterStore.filter"
      @update:modelValue="getItems()"
      :str-add-filter-selection-placeholder="
        t('Office.ShipmentsDashboard.filterSelectionPlaceholder')
      "
      :str-button-add-filter="t('Office.ShipmentsDashboard.filterAddButton')"
      :str-button-add-filter-cancel-add="t('Office.ShipmentsDashboard.filterCancelAddButton')"
      :str-button-add-filter-cancel-edit="t('Office.ShipmentsDashboard.filterCancelEditButton')"
      :str-filter-to-add-label-filter="t('Office.ShipmentsDashboard.filterLabel')"
      :str-filter-to-add-label-value="t('Office.ShipmentsDashboard.filterValueLabel')"
      :str-no-filters-set="t('Office.ShipmentsDashboard.filterEmptyLabel')"
      :str-set-filter-button="t('Office.ShipmentsDashboard.filterSetButton')"
    />
  </SuitePageHeader>
  <div class="mx-auto max-w-(--breakpoint-2xl)">
    <div class="m-4 md:m-10">
      <DataTable
        :is-loading="loading"
        :columns="columns"
        :items="shipmentsArray"
        row-selection-mode="single"
        :total-rows="shipmentsTotal"
        :rows-per-page-options="rowsPerPageOptions"
        :rows-per-page-default-option="paginationOptions.per_page"
        @update-filters="updateFilters"
        @on-page="getItems()"
        @on-search="getItems()"
        @on-row-select="onRowSelect"
      >
        <template #carrier_tracking_no="rowData">
          <template v-if="isShipmentMultiColli(rowData?.rowData)">
            <div
              :id="`toggle-multi-colli-${rowData?.rowData.id}`"
              class="text-sky my-2 h-5 font-semibold"
              @click.stop="toggleMultiColliDisplay(rowData?.rowData)"
            >
              {{
                t('Office.ShipmentsDashboard.packageAmount', {
                  number: rowData.rowData.packages.length
                })
              }}

              ({{
                isRowExpanded(rowData?.rowData)
                  ? t('Office.ShipmentsDashboard.hide')
                  : t('Office.ShipmentsDashboard.show')
              }})
            </div>

            <div class="mt-2" v-if="isRowExpanded(rowData?.rowData)">
              <div
                class="my-1 h-5"
                v-for="(pkg, index) in shipmentPackages(rowData?.rowData)"
                :key="pkg.id"
              >
                {{ temporaryPackageName(rowData?.rowData, index) }}
              </div>
            </div>
          </template>

          <template v-else>
            {{ rowData.rowData.carrier_tracking_no }}
          </template>
        </template>

        <template #carrier="rowData">
          <DataTableCarrier :carrier-name="rowData?.rowData?.carrier" />
        </template>

        <template #tracking_status="rowData">
          <template v-if="isShipmentMultiColli(rowData?.rowData)">
            <div class="" v-if="isRowExpanded(rowData?.rowData)">
              <div class="my-2 h-5"></div>

              <div
                class="my-1 h-5"
                v-for="(pkg, index) in shipmentPackages(rowData?.rowData)"
                :key="pkg.id"
              >
                <StatusCaption
                  :color="trackingStatusColors(getTrackingStatus(rowData?.rowData, index))"
                >
                  {{ t(`TrackingEvent.status.${getTrackingStatus(rowData?.rowData, index)}`) }}
                </StatusCaption>
              </div>
            </div>

            <template v-else>
              <StatusCaption :color="trackingStatusColors(shipmentStatus(rowData?.rowData))">
                {{ t(`TrackingEvent.status.${shipmentStatus(rowData?.rowData)}`) }}
              </StatusCaption>
            </template>
          </template>

          <template v-else>
            <StatusCaption :color="trackingStatusColors(getTrackingStatus(rowData?.rowData))">
              {{ t(`TrackingEvent.status.${getTrackingStatus(rowData?.rowData)}`) }}
            </StatusCaption>
          </template>
        </template>

        <template #service="rowData">
          {{ t(`Shipment.services.${rowData?.rowData?.service}`) }}
        </template>

        <template #created_at_gt="rowData">
          {{ formatDate(rowData?.rowData?.created_at) }}
        </template>

        <template #recipient="rowData">
          {{ getRecipient(rowData?.rowData) }}
        </template>

        <template #country="rowData">
          <DataTableCountry
            v-if="rowData?.rowData?.to?.country"
            :iso2="rowData?.rowData?.to?.country"
          />
        </template>

        <template #empty>
          <div class="-mt-1 rounded-none p-4 text-center" v-if="!loading && !error?.errors.length">
            {{ t('Office.ShipmentsDashboard.noShipmentsFound') }}
          </div>
          <SuiteErrorMessage class="-mt-1 rounded-none" v-if="error">
            {{ t('App.Error.fetchShipmentsError') }}
          </SuiteErrorMessage>
        </template>
      </DataTable>
    </div>
  </div>
</template>
