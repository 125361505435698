<script setup lang="ts">
import { useShippingRuleTreesStore } from '@/stores/shippingRuleTrees'
import ShippingRulesGraph, {
  type ShippingRuleTreeSaveEvent
} from '@/views/office/ShippingRulesGraph.vue'
import { SuiteErrorMessage, SuiteLoader } from '@shipcloud/suite-components'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const store = useShippingRuleTreesStore()
const { loading, error, latestShippingRuleTree } = storeToRefs(store)
store.fetchShippingRuleTrees({}, { page: 1, per_page: 1 })
const save = async (e: ShippingRuleTreeSaveEvent) => {
  store.createShippingRuleTree(e.shippingRuleTree)
}
defineExpose({ latestShippingRuleTree })
</script>

<template>
  <SuiteLoader v-if="loading" page :loading />
  <SuiteErrorMessage v-else-if="error" class="rounded-none">
    {{ t('App.Error.fetchShippingRuleTreeError') }}
  </SuiteErrorMessage>
  <ShippingRulesGraph
    v-else-if="latestShippingRuleTree"
    :initial-shipping-rule-tree="latestShippingRuleTree"
    @save="save"
  />
</template>
