<script lang="ts" setup>
import { Delivery, GetDelivery } from '@/api/parcelCore'
import {
  SuiteErrorMessage,
  SuiteIcon,
  SuiteItemDetails,
  SuiteLoader
} from '@shipcloud/suite-components'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { deliveryToSuiteItemDetails } from './helpers'

type DeliveryDetailsProps = { id: string }
const props = defineProps<DeliveryDetailsProps>()

const delivery = ref<Delivery>()
const loading = ref(false)
const error = ref()
watch(
  props,
  async () => {
    loading.value = true
    delivery.value = undefined
    error.value = undefined

    const result = await GetDelivery(props.id)

    if ('status' in result) {
      error.value = result
    } else {
      delivery.value = result
    }
    loading.value = false
  },
  { immediate: true }
)

const i18n = useI18n()
const { t } = i18n
const detailsProps = computed(() => deliveryToSuiteItemDetails(delivery.value, i18n))
</script>

<template>
  <SuiteLoader page :loading />
  <template v-if="!loading">
    <SuiteErrorMessage class="rounded-none" v-if="error">{{
      t('App.Error.fetchShipmentError')
    }}</SuiteErrorMessage>
    <SuiteItemDetails v-else-if="detailsProps" v-bind="detailsProps">
      <template #header>
        <RouterLink
          :to="{ name: 'parcel-core-shipments-dashboard' }"
          class="inline-flex items-center"
          data-identifier="link-back"
        >
          <SuiteIcon icon="ChevronLeft" class="mr-2 size-4" />
          {{ t('Office.ShipmentDetails.back') }}
        </RouterLink>
      </template>
    </SuiteItemDetails>
  </template>
</template>
