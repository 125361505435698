<script setup lang="ts">
import type { ShippingRule } from '@/api/shipcloud/shipping_rules'
import {
  SuiteButton,
  SuiteIcon,
  SuiteSelect,
  SuiteToolTip,
  ThemeEnum,
  type SuiteSelectOption
} from '@shipcloud/suite-components'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import ShippingRuleOutputEditor from './ShippingRuleOutputEditor.vue'
import { shippingRuleSchemas, type ShippingRuleSchema } from './shippingRuleSchemas'
import { useShippingRuleEditor } from './useShippingRuleEditor'

const props = withDefaults(
  defineProps<{
    shippingRule?: ShippingRule
    schemas?: ShippingRuleSchema[]
    strRuleSelectorPlaceholder?: string
    strOutputsHeading?: string
    strOutputsAddRow?: string
    strButtonCancel?: string
    strButtonSubmit?: string
    strShippingRuleSchemaFromCity?: string
    strShippingRuleSchemaFromCompany?: string
    strShippingRuleSchemaFromCountry?: string
    strShippingRuleSchemaFromZipCode?: string
    strShippingRuleSchemaToCity?: string
    strShippingRuleSchemaToCompany?: string
    strShippingRuleSchemaToCountry?: string
    strShippingRuleSchemaToZipCode?: string
    strShippingRuleSchemaPackageWeight?: string
    strShippingRuleSchemaPackageLongestSide?: string
    strShippingRuleSchemaReceiverCountryInEea?: string
    strShippingRuleSchemaReceiverCompanyNotEmpty?: string
    strShippingRuleSchemaReturnShipment?: string
  }>(),
  {
    schemas: () => shippingRuleSchemas,
    strRuleSelectorPlaceholder: 'Pick a rule',
    strOutputsHeading: 'Outputs',
    strOutputsAddRow: 'Add Output',
    strButtonCancel: 'Discard',
    strButtonSubmit: 'Apply',
    strShippingRuleSchemaFromCity: 'Sender city',
    strShippingRuleSchemaFromCompany: 'Sender company',
    strShippingRuleSchemaFromCountry: 'Sender country',
    strShippingRuleSchemaFromZipCode: 'Sender zip code',
    strShippingRuleSchemaToCity: 'Receiver city',
    strShippingRuleSchemaToCompany: 'Receiver company',
    strShippingRuleSchemaToCountry: 'Receiver country',
    strShippingRuleSchemaToZipCode: 'Receiver zip code',
    strShippingRuleSchemaPackageWeight: 'Package weight',
    strShippingRuleSchemaPackageLongestSide: 'Package longest side',
    strShippingRuleSchemaReceiverCountryInEea: 'Receiver country in EEA',
    strShippingRuleSchemaReceiverCompanyNotEmpty: 'Receiver company not empty',
    strShippingRuleSchemaReturnShipment: 'Return shipment'
  }
)

const emit = defineEmits<{
  (e: 'cancel'): unknown
  (e: 'update', payload: ShippingRule): unknown
}>()

const {
  activeSchemaIndex,
  ruleOutputsAdd,
  ruleOutputsDelete,
  schemaOptions,
  operators,
  activeOperator,
  activeInputComponent,
  ruleValue,
  ruleOutputs,
  updatePayload,
  processRule,
  setSchemas
} = useShippingRuleEditor({
  rule: props.shippingRule,
  schemas: props.schemas
})

// Process props.schemas on any update.
watch(
  () => props.schemas,
  () => setSchemas(props.schemas),
  { deep: true }
)

// Process props.shippingRule on any update.
watch(
  () => props.shippingRule,
  () => processRule(props.shippingRule)
)

const { t } = useI18n()

// Rule selector options.
const schemaOptionsWithPlaceholder = computed<SuiteSelectOption[]>(() => {
  const mappedOptions = schemaOptions.value.map(
    (option: (typeof schemaOptions.value)[0]): SuiteSelectOption => ({
      ...option,
      label: props[option.label as keyof typeof props] as string
    })
  )

  if (props.strRuleSelectorPlaceholder) {
    return [
      {
        // Placeholder entry
        selected: true,
        value: -1,
        label: props.strRuleSelectorPlaceholder,
        disabled: true
      },
      ...mappedOptions
    ]
  }

  return mappedOptions
})
</script>

<template>
  <div>
    <div class="grid grid-cols-[3fr_1fr_20rem_auto] items-center gap-2">
      <div>
        <SuiteSelect v-model.number="activeSchemaIndex" :options="schemaOptionsWithPlaceholder" />
      </div>
      <div>
        <SuiteSelect v-if="operators.length" v-model="activeOperator" :options="operators" />
      </div>
      <div>
        <component
          v-if="activeInputComponent"
          :is="activeInputComponent.component"
          v-bind="activeInputComponent.props || {}"
          v-model="ruleValue"
        ></component>
      </div>
      <div>
        <SuiteToolTip v-if="t('Office.ShippingRules.EditDialog.ruleTooltip')">
          <template #button>
            <SuiteIcon icon="Info" class="text-sky size-5" />
          </template>
          {{ t('Office.ShippingRules.EditDialog.ruleTooltip') }}
        </SuiteToolTip>
      </div>
    </div>

    <div class="border-b border-gray-200 pt-4">
      <h3 class="text-sky text-base leading-6 font-semibold">
        {{ strOutputsHeading }}
      </h3>
    </div>

    <div class="space-y-2 pt-4">
      <ShippingRuleOutputEditor
        v-for="(output, index) in ruleOutputs"
        :key="`rule-output-${index}`"
        v-model:output="ruleOutputs[index]"
        @delete="() => ruleOutputsDelete(index)"
      />
      <SuiteButton id="add-output" class="justify-center" @click="() => ruleOutputsAdd()">
        {{ strOutputsAddRow }}
      </SuiteButton>
      <div class="text-right">
        <SuiteButton @click="emit('cancel')">
          {{ strButtonCancel }}
        </SuiteButton>
        <SuiteButton
          @click="() => updatePayload && emit('update', updatePayload)"
          :class="['ml-2', { 'cursor-not-allowed': !updatePayload }]"
          :theme="!updatePayload ? ThemeEnum.BLUE_OUTLINE : undefined"
        >
          {{ strButtonSubmit }}
        </SuiteButton>
      </div>
    </div>
  </div>
</template>
