<script setup lang="ts">
import type {
  DetailColumn,
  DetailColumnItem,
  DetailHeaderBarItem,
  SimpleDataTableColumn,
  SimpleDataTableItem
} from '@shipcloud/suite-components'
import {
  DetailColumnBar,
  DetailDivider,
  DetailHeaderBar,
  DialogBox,
  SimpleDataTable,
  StatusCaption,
  SuiteButton,
  SuiteErrorMessage,
  SuiteEventTimeline,
  SuiteIcon,
  SuiteLoader,
  ThemeEnum
} from '@shipcloud/suite-components'
import { storeToRefs } from 'pinia'
import type { ComputedRef } from 'vue'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink, useRouter } from 'vue-router'

import {
  type CustomsDeclaration,
  type Package,
  type Shipment,
  type TrackingEvent
} from '@/api/shipcloud/shipment'
import SuccessMessagePanel from '@/components/SuccessMessagePanel.vue'
import { addressLines, lastTrackingEvent, shortId, trackingStatusColors, weight } from '@/helpers'
import { useCarriersStore } from '@/stores/carriers'
import { useShipmentsStore } from '@/stores/shipments'

const { d, n, t } = useI18n()
const router = useRouter()
const currentRoute = router.currentRoute

const props = defineProps<{
  id: string
}>()

const shipmentsStore = useShipmentsStore()
const { loading, error, saveSuccess, inlineReturnId } = storeToRefs(shipmentsStore)
const carriersStore = useCarriersStore()
const success = ref('')

const errorMessages = computed(() => {
  const messages: string[] = []
  if (error.value?.status) {
    if (error.value.action === 'delete') {
      if (error.value.status === 404) {
        messages.push(t('App.Error.deleteNonExistentShipmentError'))
      }
      if (500 <= error.value.status && error.value.status <= 599) {
        messages.push(t('App.Error.deleteShipmentError'))
      }
    }
  }
  if (!messages.length) messages.push(t('App.Error.fetchShipmentError'))
  if (Array.isArray(error.value?.errors)) {
    error.value.errors.slice(0, 3).forEach((msg) => messages.push(msg))
  }
  return messages
})

const shipment = computed(() => shipmentsStore.shipmentById(props.id))
const isReturnShipment = computed(() => shipment.value?.service === 'returns')
shipmentsStore.fetchShipment(props.id)
watch(props, () => shipmentsStore.fetchShipment(props.id))
watch(currentRoute, () => {
  inlineReturnId.value = undefined
  saveSuccess.value = false
})
const renderCreateReturnButton = ref(false)
watch(shipment, async () => {
  if (shipment.value) {
    await carriersStore.fetchCarriers()
    renderCreateReturnButton.value = carriersStore.carrierSupportsReturns(shipment.value.carrier)
  }
})

const detailHeaderBarItems = (shipment: Shipment): DetailHeaderBarItem[] => {
  return [
    {
      label: t('Shipment.id'),
      value: shortId(shipment.id)
    },
    { label: t('Shipment.created_at'), value: d(shipment.created_at) },
    {
      label: t('Shipment.carrier'),
      value: t(`Shipment.carriers.${shipment.carrier}`)
    },
    {
      label: t('Shipment.service'),
      value: t(`Shipment.services.${shipment.service}`)
    }
  ]
}
const collectAdditionalServiceProperties = (properties: Map<string, string>): string => {
  const list: string[] = []
  for (const [key, value] of Object.entries(properties)) {
    const name = t(`AdditionalService.properties.${key}`)
    list.push(`${name}: ${value}`)
  }
  return list.join('\n')
}
const detailColumns = (shipment: Shipment): Array<DetailColumn & { hidden?: boolean }> => {
  const firstColumn = {
    title: t('Office.ShipmentDetails.shipmentDetails'),
    items: [
      {
        label: t('Shipment.from'),
        value: addressLines(shipment.from).join('\n')
      }
    ]
  } satisfies DetailColumn
  if (shipment.reference_number) {
    firstColumn.items.push({
      label: t('Shipment.reference_number'),
      value: String(shipment.reference_number)
    })
  }
  if (shipment.additional_services) {
    const withoutProperties = shipment.additional_services.filter(
      (additionalService) => additionalService.properties == null
    )
    const withProperties = shipment.additional_services.filter(
      (additionalService) => additionalService.properties !== null
    )
    const translate = (name: string) => t(`AdditionalService.names.${name}`, {}, { default: name })
    if (withoutProperties.length) {
      firstColumn.items.push({
        label: t('Shipment.additional_services'),
        value: withoutProperties
          .map((additionalService) => {
            return translate(additionalService.name)
          })
          .join('\n')
      })
    }
    if (withProperties.length) {
      for (const additionalService of withProperties) {
        firstColumn.items.push({
          label: translate(additionalService.name),
          value: collectAdditionalServiceProperties(additionalService.properties!)
        })
      }
    }
  }
  const thirdColumn: DetailColumn = {
    title: t('Office.ShipmentDetails.financialDetails'),
    items: [
      {
        label: t('Shipment.price'),
        value: n(Number(shipment.price), 'currency')
      }
    ]
  }
  if (shipment.surcharges) {
    if (shipment.surcharges.pre_calculated?.length) {
      thirdColumn.items.push({
        label: t('Office.ShipmentDetails.precalculatedSurcharges')
      })
      shipment.surcharges.pre_calculated.forEach((surcharge) => {
        thirdColumn.items.push({
          label: surcharge.category,
          value: n(Number(surcharge.amount), 'currency')
        })
      })
    }
    if (shipment.surcharges.post_calculated?.length) {
      thirdColumn.items.push({
        label: t('Office.ShipmentDetails.postcalculatedSurcharges')
      })
      shipment.surcharges.post_calculated.forEach((surcharge) => {
        thirdColumn.items.push({
          label: surcharge.category,
          value: n(Number(surcharge.amount), 'currency')
        })
      })
    }
  }

  return [
    firstColumn,
    {
      title: t('Office.ShipmentDetails.customerDetails'),
      items: [
        {
          label: t('Shipment.to'),
          value: shipment?.to ? addressLines(shipment.to).join('\n') : ''
        },
        {
          label: t('Shipment.email'),
          value: shipment.to?.email || ''
        }
      ]
    },
    thirdColumn
  ]
}

const customsDeclarationCurrencyValue = (value: string, currency: string) => {
  return currency == 'EUR' ? n(Number(value), 'currency') : `${value} ${currency}`
}
const customsDeclarationDetailColumns = (
  customsDeclaration: CustomsDeclaration
): DetailColumn[] => {
  const firstColumn: DetailColumnItem[] = [
    {
      label: t('CustomsDeclaration.contents_type'),
      value: t(`CustomsDeclaration.contents_types.${customsDeclaration.contents_type}`)
    },
    {
      label: t('CustomsDeclaration.contents_explanation'),
      value: customsDeclaration.contents_explanation || ''
    },
    {
      label: t('CustomsDeclaration.posting_date'),
      value: customsDeclaration.posting_date ? d(customsDeclaration.posting_date) : ''
    },
    {
      label: t('CustomsDeclaration.document_type'),
      value: t(`CustomsDeclaration.document_types.${customsDeclaration.document_type}`)
    }
  ]
  if (customsDeclaration.buyer_address)
    firstColumn.push({
      label: t('CustomsDeclaration.buyer_address'),
      value: addressLines(customsDeclaration.buyer_address).join('\n')
    })
  return [
    {
      title: t('Shipment.customs_declaration'),
      items: firstColumn
    },
    {
      title: t('Shipment.customs_declaration'),
      items: [
        {
          label: t('CustomsDeclaration.invoice_number'),
          value: customsDeclaration.invoice_number || ''
        },
        {
          label: t('CustomsDeclaration.exporter_reference'),
          value: customsDeclaration.exporter_reference || ''
        },
        {
          label: t('CustomsDeclaration.importer_reference'),
          value: customsDeclaration.importer_reference || ''
        },
        {
          label: t('CustomsDeclaration.drop_off_location'),
          value: customsDeclaration.drop_off_location || ''
        }
      ]
    },
    {
      title: t('Office.ShipmentDetails.financialDetails'),
      items: [
        {
          label: t('CustomsDeclaration.total_value_amount'),
          value: customsDeclarationCurrencyValue(
            customsDeclaration.total_value_amount,
            customsDeclaration.currency
          )
        },
        {
          label: t('CustomsDeclaration.additional_fees'),
          value: customsDeclaration.additional_fees
            ? customsDeclarationCurrencyValue(
                customsDeclaration.additional_fees,
                customsDeclaration.currency
              )
            : ''
        }
      ]
    }
  ]
}
const customsDeclarationDetailHeaderBarItems = computed(() => {
  const customsDeclaration = shipment?.value?.customs_declaration

  if (!customsDeclaration) return undefined

  return [
    {
      label: t('CustomsDeclaration.contents_type'),
      value: t(`CustomsDeclaration.contents_types.${customsDeclaration.contents_type}`)
    },
    {
      label: t('CustomsDeclaration.contents_explanation'),
      value: customsDeclaration.contents_explanation
    },
    {
      label: t('CustomsDeclaration.posting_date'),
      value: customsDeclaration.posting_date ? d(customsDeclaration.posting_date) : ''
    },
    {
      label: t('CustomsDeclaration.document_type'),
      value: t(`CustomsDeclaration.document_types.${customsDeclaration.document_type}`)
    }
  ] as DetailHeaderBarItem[]
})

const customsDeclarationItemColumns: SimpleDataTableColumn[] = [
  { field: 'quantity', label: t('CustomsDeclarationItem.quantity') },
  { field: 'description', label: t('CustomsDeclarationItem.description') },
  { field: 'origin_country', label: t('CustomsDeclarationItem.origin_country') },
  { field: 'value_amount', label: t('CustomsDeclarationItem.value_amount') },
  { field: 'net_weight', label: t('CustomsDeclarationItem.net_weight') },
  { field: 'gross_weight', label: t('CustomsDeclarationItem.gross_weight') },
  { field: 'hs_tariff_number', label: t('CustomsDeclarationItem.hs_tariff_number') }
]
const packageColumns: SimpleDataTableColumn[] = [
  { field: 'carrier_tracking_no', label: t('Package.carrier_tracking_no') },
  { field: 'description', label: t('Package.description') },
  { field: 'dimensions', label: t('Package.dimensions') },
  { field: 'weight', label: t('Package.weight') },
  { field: 'package_type', label: t('Package.package_type') },
  { field: 'status', label: t('Package.status') },
  { field: 'document_url' }
]
const packageDetails = (pkg: Package, shipment: Shipment): SimpleDataTableItem => {
  const dimensions = [n(pkg.height), n(pkg.width), n(pkg.length)]
    .sort()
    .reverse()
    .join(' cm x ')
    .concat(' cm')
  return {
    carrier_tracking_no: shipment.carrier_tracking_no,
    description: pkg.description,
    dimensions: dimensions,
    weight: weight(n(pkg.weight)),
    package_type: t(`Package.package_types.${pkg.type}`),
    status: lastTrackingEvent(pkg)?.status || 'ignored',
    document_url: { document_url: shipment.label_url, qrcode_url: shipment.label_voucher_url },
    tracking_events: pkg.tracking_events
  }
}
const packagesArray: ComputedRef<SimpleDataTableItem[]> = computed(() => {
  let result: SimpleDataTableItem[] = []

  shipment.value.packages.forEach((pkg) => {
    result.push(packageDetails(pkg, shipment.value))
  })

  return result
})
const trackingStatusEvents = (trackingEvents: TrackingEvent[]) => {
  let events: any[] = [] // Event export mising

  for (const trackingEvent of trackingEvents) {
    events.push({
      title: trackingEvent.location,
      id: trackingEvent.id,
      timestamp: d(trackingEvent.timestamp),
      status: trackingEvent.status,
      body: trackingEvent.details
    })
  }
  return events
}
const openLabel = (href: string) => {
  window.open(href, '_blank', 'noopener noreferrer')
}

const deleteDialogOpen = ref(false)
const deleteShipment = async () => {
  deleteDialogOpen.value = false
  const deleted_shipment_data = await shipmentsStore.deleteShipment(props.id)
  if (deleted_shipment_data === true) {
    success.value = t('Office.ShipmentDetails.shipmentDeletion.successfullyDeleted')
    setTimeout(() => router.push({ name: 'shipments-dashboard' }), 1000)
  }
}
defineExpose({ deleteShipment })
</script>

<template>
  <DialogBox
    v-model="deleteDialogOpen"
    dialog-size="small"
    :dialog-title="t('Office.ShipmentDetails.shipmentDeletion.ConfirmDeletionDialogHeader')"
  >
    <div class="mt-4 min-w-96 px-4">
      {{ t('Office.ShipmentDetails.shipmentDeletion.ConfirmDeletionDialogText') }}
    </div>
    <div class="flex justify-end gap-2 p-4">
      <SuiteButton @click="deleteDialogOpen = false" :theme="ThemeEnum.BLUE_OUTLINE">
        {{ t('Office.ShipmentDetails.shipmentDeletion.CancelDeletionButtonText') }}
      </SuiteButton>
      <SuiteButton :theme="ThemeEnum.RED_OUTLINE" @click="deleteShipment()">
        {{ t('Office.ShipmentDetails.shipmentDeletion.ConfirmDeletionButtonText') }}
      </SuiteButton>
    </div>
  </DialogBox>

  <div class="mx-auto max-w-(--breakpoint-2xl)">
    <div class="m-4 md:m-10 lg:mx-20">
      <div className="h-12 flex place-items-center p-1 bg-sky rounded-tl-xl rounded-tr-xl ">
        <div className="text-white text-sm font-bold leading-none tracking-tight">
          <RouterLink :to="{ name: 'shipments-dashboard' }" class="inline-flex items-center">
            <SuiteIcon icon="ChevronLeft" class="mr-2 size-4" />

            {{ t('Office.ShipmentDetails.back') }}
          </RouterLink>
        </div>
        <div v-if="shipment" class="ml-auto flex gap-2">
          <SuiteButton
            id="copy-shipment-button"
            class="group h-10"
            icon="Plus"
            v-if="!isReturnShipment"
            @click="router.push({ name: 'create-shipment-from-existing', params: { id } })"
          >
            <span class="hidden group-hover:block">
              {{ t('Office.ShipmentDetails.duplicateButton') }}
            </span>
          </SuiteButton>
          <SuiteButton
            v-if="renderCreateReturnButton"
            id="create-return-button"
            class="group h-10"
            icon="ParcelCycleCurrentcolor"
            @click="router.push({ name: 'create-return-from-existing', params: { id } })"
          >
            <span class="hidden group-hover:block">
              {{ t('Office.ShipmentDetails.returnButton') }}
            </span>
          </SuiteButton>
          <SuiteButton
            id="delete-shipment-button"
            class="group h-10"
            icon="Delete2"
            icon-class="text-red-500"
            @click="deleteDialogOpen = !deleteDialogOpen"
          >
            <span class="hidden text-red-500 group-hover:block">
              {{ t('Office.ShipmentDetails.deleteButton') }}
            </span>
          </SuiteButton>
        </div>
      </div>

      <SuiteLoader v-if="loading" page :loading />
      <div v-else class="rounded-br-lg rounded-bl-lg bg-white">
        <SuccessMessagePanel v-if="inlineReturnId">
          <RouterLink :to="{ name: 'shipment-details', params: { id: inlineReturnId } }">
            {{ t('Office.ShipmentForm.inlineReturn') }}
          </RouterLink>
        </SuccessMessagePanel>

        <SuccessMessagePanel v-if="saveSuccess" auto-hide>
          {{
            shipment.service == 'returns'
              ? t('Office.ShipmentForm.saveReturnSuccess')
              : t('Office.ShipmentForm.saveSuccess')
          }}
        </SuccessMessagePanel>

        <SuiteErrorMessage v-if="error" class="rounded-t-none">
          <div v-for="err in errorMessages" :key="err">
            {{ err }}
          </div>
        </SuiteErrorMessage>

        <SuccessMessagePanel v-else-if="success" auto-hide>
          {{ success }}
        </SuccessMessagePanel>

        <div v-if="shipment">
          <DetailHeaderBar class="bg-slate-50" :items="detailHeaderBarItems(shipment)" />
          <DetailColumnBar :columns="detailColumns(shipment)" />
          <DetailDivider
            class="m-5 mt-14"
            :icon="t('Office.ShipmentDetails.packages')[0].toUpperCase()"
            :text="t('Office.ShipmentDetails.packages')"
          />

          <SimpleDataTable :columns="packageColumns" :items="packagesArray" expandable class="mx-5">
            <template #status="{ columnData }">
              <StatusCaption :color="trackingStatusColors(columnData)">
                {{ t(`TrackingEvent.status.${columnData}`) }}
              </StatusCaption>
            </template>

            <template #document_url="{ columnData: { document_url, qrcode_url } }">
              <div class="flex gap-2">
                <SuiteButton
                  @on-click="openLabel(document_url)"
                  icon="FileText"
                  href="document_url"
                  class="h-7 w-7 p-1"
                />
                <SuiteButton
                  v-if="qrcode_url"
                  @on-click="openLabel(qrcode_url)"
                  icon="Qrcode"
                  href="qrcode_url"
                  class="h-7 w-7 p-1"
                />
              </div>
            </template>

            <template #expand-toggle="{ isExpanded }">
              <button
                type="button"
                class="text-sky cursor-pointer items-center text-sm"
                :class="isExpanded ? 'flex' : 'hidden'"
              >
                {{ t('Office.ShipmentDetails.showLess') }}
              </button>

              <button
                type="button"
                class="text-sky cursor-pointer items-center text-sm"
                :class="isExpanded ? 'hidden' : 'flex'"
              >
                {{ t('Office.ShipmentDetails.showMore') }}
              </button>
            </template>

            <template #expanded-row="{ rowData }">
              <template v-if="rowData?.tracking_events?.length">
                <SuiteEventTimeline :events="trackingStatusEvents(rowData.tracking_events)" />
              </template>

              <div class="text-center" v-else>{{ t('TrackingEvent.no_events') }}</div>
            </template>
          </SimpleDataTable>

          <div class="mt-7" v-if="shipment.customs_declaration">
            <div class="flex">
              <DetailDivider
                class="m-5"
                :icon="t('Shipment.customs_declaration')[0].toUpperCase()"
                :text="t('Shipment.customs_declaration')"
              />
            </div>

            <DetailHeaderBar
              class="bg-slate-50"
              v-if="customsDeclarationDetailHeaderBarItems"
              :items="customsDeclarationDetailHeaderBarItems"
            >
              <template #end v-if="shipment.customs_declaration.carrier_declaration_document_url">
                <SuiteButton
                  @on-click="
                    openLabel(shipment.customs_declaration.carrier_declaration_document_url)
                  "
                  icon="FileText"
                  href="columnData"
                  class="max-h-9 px-3 py-2 text-sm font-semibold"
                >
                  <template class="hidden lg:block">{{
                    t('Office.ShipmentDetails.carrierDeclarationDocument')
                  }}</template></SuiteButton
                >
              </template>
            </DetailHeaderBar>

            <DetailColumnBar
              :columns="customsDeclarationDetailColumns(shipment.customs_declaration)"
            />

            <SimpleDataTable
              :columns="customsDeclarationItemColumns"
              :items="shipment.customs_declaration.items"
              class="m-5"
            >
              <template #value_amount="{ columnData }">
                {{ customsDeclarationCurrencyValue(columnData, 'EUR') }}
              </template>

              <template #net_weight="{ columnData }">
                {{ weight(n(Number(columnData))) }}
              </template>

              <template #gross_weight="{ columnData }">
                <template v-if="columnData">
                  {{ weight(n(Number(columnData))) }}
                </template>
              </template>
            </SimpleDataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
