<script setup lang="ts">
import type { Shipment } from '@/api/parcelCore'
import {
  SuiteCarrierLogo,
  SuiteEventTimeline,
  type SuiteCarrierLogoProps,
  type SuiteEventTimelineEvent
} from '@shipcloud/suite-components'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  shipment: Shipment
}>()

const { d } = useI18n()

const events = computed(() => {
  if (!props.shipment.shipmentItem?.events?.length) return undefined
  return props.shipment.shipmentItem.events.map(
    (event: any, eventIndex) =>
      ({
        timestamp: d(event.timestamp, 'long'),
        title: event.status,
        status: event.status,
        body: 'test', // @FIXME What content should be placed here?
        id: `${props.shipment.id}-${eventIndex + 1}`
      }) as SuiteEventTimelineEvent
  )
})

const carrierLogoProps = computed(() => {
  const carrierMapping: Record<string, SuiteCarrierLogoProps['carrier']> = {
    UPS_DE: 'ups',
    SEND_ES: 'sending'
  }

  if (!carrierMapping?.[props.shipment.carrierCode]) {
    return undefined
  }

  return {
    carrier: carrierMapping[props.shipment.carrierCode],
    alt: props.shipment.carrierName
  } as SuiteCarrierLogoProps
})
</script>

<template>
  <div>
    <div class="flex items-center gap-4">
      <SuiteCarrierLogo v-if="carrierLogoProps" v-bind="carrierLogoProps" class="size-16" />
      <span v-else>{{ shipment.carrierName }}</span>
      <span>{{ shipment.productName }}</span>
    </div>
    <SuiteEventTimeline v-if="events" :events="events" />
  </div>
</template>
