<script lang="ts" setup>
import { InlineReturn } from '@/api/shipcloud/shipment'
import { SuiteIcon, SuiteInput, SuiteInputLabel, SuiteToolTip } from '@shipcloud/suite-components'
import { useI18n } from 'vue-i18n'

const inlineReturn = defineModel<InlineReturn>()!
const { t } = useI18n()
</script>

<template>
  <template v-if="inlineReturn">
    <SuiteInputLabel>
      <template #label>{{ t('Office.ShipmentFormInlineReturn.create') }}</template>
      <input id="inline-return-create" type="checkbox" v-model="inlineReturn.create" />
    </SuiteInputLabel>
    <div v-if="inlineReturn.create" class="flex justify-between">
      <SuiteInputLabel label-position="top">
        <template #label>{{ t('Office.ShipmentFormInlineReturn.referenceNumber') }}</template>
        <SuiteInput v-model="inlineReturn.reference_number" />
      </SuiteInputLabel>
      <SuiteToolTip>
        <template #button>
          <SuiteIcon icon="Info" class="text-sky w-5" />
        </template>
        <span v-if="inlineReturn.defaultReturnsAddressPresent">
          {{ t('Office.ShipmentFormInlineReturn.defaultReturnAddressFound') }}
        </span>
        <span v-else>
          {{ t('Office.ShipmentFormInlineReturn.defaultReturnAddressNotFound') }}
        </span>
      </SuiteToolTip>
    </div>
  </template>
</template>
