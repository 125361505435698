<script setup lang="ts">
import { SuiteInput } from '@shipcloud/suite-components'

const value = defineModel<[number, number]>({ default: [0, 0] })
const valueAsNumberFromEvent = (e: Event) =>
  ((e as InputEvent).target as HTMLInputElement).valueAsNumber
</script>

<template>
  <span class="grid grid-cols-2 gap-2">
    <SuiteInput
      type="number"
      :value="value[0]"
      @input="(e: InputEvent) => (value = [valueAsNumberFromEvent(e), value[1]])"
    >
    </SuiteInput>
    <SuiteInput
      type="number"
      :value="value[1]"
      @input="(e: InputEvent) => (value = [value[0], valueAsNumberFromEvent(e)])"
    >
    </SuiteInput>
  </span>
</template>
